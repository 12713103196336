<template>
  <div class="bg-main messages vh-100">
    <VuePullRefresh
      :key="pullKey"
      :on-refresh="appRefresh"
      :config="{ startLabel: '', readyLabel: '', loadingLabel: '', pullDownHeight: 60 }"
    >
      <v-container class="py-0 px-0 pb-10" fluid>
        <v-alert
          v-if="!$online"
          dense
          tile
          color="gray"
          icon="mdi-alert-circle-outline"
          class="alert-offline text-headline"
        >
          {{ $t("t_no_connection") }}
        </v-alert>
        <v-card flat class="bg-main" tile>
          <v-card-text class="d-flex pb-2" v-if="$platform == 'web'">
            <p class="headline mb-0">{{ $t("v_messages.t_messages") }}</p>
            <v-spacer></v-spacer>

            <v-btn
              v-if="$vuetify.breakpoint.mdAndUp"
              icon
              class="ml-2"
              :class="{ rotate: this.$store.state.loader.loading }"
              @click="refresh"
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
          </v-card-text>
          <MessageList ref="messageList" :messagesTakeNum="20" />
        </v-card>
      </v-container>
    </VuePullRefresh>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Helpers from "@/mixins/helpers";
import MessageList from "@/components/messages/MessageList.vue";
import VuePullRefresh from "vue-pull-refresh";
import { Capacitor } from "@capacitor/core";

export default {
  name: "Messages",
  mixins: [Helpers],
  components: { MessageList, VuePullRefresh },
  computed: {
    ...mapGetters("notifications", ["notifications"]),
  },
  watch: {
    notifications() {
      if (Capacitor.isPluginAvailable("PushNotifications") && this.notifications?.length > 0) {
        this.$refs.messageList.loadMessages(true);
        this.removePushNotifications();
      }
    },
  },
  data: () => ({
    pullKey: 0,
    pushes: null,
  }),

  methods: {
    ...mapActions("notifications", ["removePushNotifications", "getPushNotifications"]),

    refresh() {
      this.$refs.messageList.loadMessages(true);
    },
    appRefresh() {
      this.$refs.messageList.loadMessages(true);
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
          this.pullKey += 1;
        }, 1000);
      });
    },
  },
  mounted() {
    if (Capacitor.isPluginAvailable("PushNotifications")) {
      //TODO - when the api has this endpoint....
      // const token = NotificationService.getFCMToken(this.$deviceId);
      // if (DateTime.fromISO(token.pushRegistrationExpiresOn) < DateTime.now()) {
      //   this.requestPushNotifications();
      // }

      this.removePushNotifications();
      this.getPushNotifications();
    }
  },
};
</script>
